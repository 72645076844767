
html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}
